import React from 'react';
import "./life.css";
import logoLife from "../../logo.png";
import oneLife from "../../assests/young-sports-people-training-morning-gym (1).jpg";
import towLife from "../../assests/dollar-gill-QoW2Sdlh9Nk-unsplash.jpg";
import threeLife from "../../assests/juan-pablo-rodriguez-X6jtULYJQz8-unsplash.jpg";
import fourLife from "../../assests/mark-bertulfo-4WcuyZ_f5ns-unsplash.jpg";
import fiveLife from "../../assests/fortune-vieyra-jD4MtXnsJ6w-unsplash.jpg";
import sixLife from "../../assests/jonathan-borba-H6wTktsFxik-unsplash.jpg";
import sevenLife from "../../assests/gursimrat-ganda-TT042bOytlU-unsplash.jpg";
import eaghtLife from "../../assests/total-shape-jkUVJi6rHUM-unsplash.jpg";
import nineLife from "../../assests/emmanuel-chigbo-jr7R01lOn1Y-unsplash.jpg";
import tenLife from "../../assests/trust-tru-katsande-Pu7BOaYlYGY-unsplash.jpg";
import { Link } from 'react-router-dom';
import {motion} from "framer-motion";
import ListLife from '../parts/listLife';


const Life = () => {
    const hash = [
        {name:"Training"},
        { name:"CLUB"},
        {name:"RUN"},
        {name:"GROUP"},
        {name:"GIRLS"},
        {name:"Boys"},
        {name:"COACH"},
        {name:"RELAXATION"},
        {name:"TEAM"},
        {name:"SINGELE"},
        {name:"SMILE"},
        {name:"MUSCLES"}
    ]
    return (
        <div>
            <div className="overflow-hidden">
                <Link to="/" className='goBack'><i class="fa-regular fa-circle-left"></i></Link>
                <div className="row">
                    <div className="col-3 col-md-2">
                        <div className="side-life-club">
                            <div className="w-100 text-center p-3 d-none d-md-inline-block">
                                <img className="w-75 opacity-75 img-fluid" src={logoLife} alt="" />
                            </div>
                            <ul className="list-unstyled p-1 p-sm-3">
                                <ListLife hash={hash} />
                            </ul>
                        </div>
                    </div>
                    <div className="col-9 col-md-10">
                        <div className="life-cards row">
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative ">
                                    <img className="w-100 h-100 img-fluid" src={oneLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={towLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={threeLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={fourLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={fiveLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={sixLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={sevenLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={eaghtLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={nineLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                            <div className="life-card col-12 col-md-6">
                                <div className="w-100 position-relative">
                                    <img className="w-100 h-100 img-fluid" src={tenLife} alt="" />
                                    <span className="placeholder-life position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center fs-1">
                                        Training
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Life;
