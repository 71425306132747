import React from 'react';
import "./contact.css";
import mapImg from "../../assests/map.png";
import ContactFild from '../parts/contactFild';
const Contact = () => {
    const contactInfo = [
        {
            name: "WhatsApp",
            valu: "0097059848236",
            icon: "fa-brands fa-whatsapp"
        },
        {
            name: "Phone",
            valu: "0097059848236",
            icon: "fa-solid fa-phone"
        },
        {
            name: "Email",
            valu: "Ex@gmail.com",
            icon: "fa-solid fa-at"
        },

    ]   

    return (
        <div>
            <div id='contact' className="container overflow-hidden" style={{padding:"90px 0"  }}>
                <div className="row">
                    <div className="col-12 col-lg-6 p-4">
                        <span className="text-white-50 lead">
                            You can contact us from here in case of any
                            inquiries
                        </span>
                        <ul className="list-unstyled pt-3">
                            <ContactFild info={contactInfo} />
                        </ul>
                        <div className="time-open py-4">
                            <span className="text-white-50">Working hours <b>08:00 - 12:00 </b> </span><br />
                             <span className="text-white-50">All days of the week except Friday</span>
                        </div>
                        <div className="pt-4 pr-4 pb-4">
                            <span className="text-white">CopyRight The House GYM 2000-{(new Date().getFullYear())}</span>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="w-100 d-none d-lg-inline-block">
                            <img className="w-100 img-fluid img-thumbnail" src={mapImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
