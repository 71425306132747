import React from 'react';
import "./plain.css";
import { Link } from 'react-router-dom';
import {motion} from "framer-motion";
const Plain = () => {
    return (
        <div>
            <div className="container-lg overflow-hidden">
                <Link to="/" className='goBack'><i class="fa-regular fa-circle-left"></i></Link>
                <div>
                    <h1 className="head-plain fw-lighter text-white d-inline-block p-5">Join Now</h1>
                </div>
                <div className=" row g-md-2 g-lg-5 justify-content-center align-items-center my-5 ">
                    <div className="col-12 col-sm-4">
                        <div className="box-plain bg-white d-flex flex-column justify-content-between position-relative">
                            <span className="plain-icon position-absolute top-0 start-50 translate-middle d-flex justify-content-center align-items-center rounded-circle"><i className="fa-solid fa-person-running"></i></span>
                            <span className="plain-price position-absolute top-0 end-0 d-flex justify-content-center fw-light align-items-center">
                                $25
                            </span>
                            <div className="pt-5">
                                <h3 className="name-plain fw-light px-3 py-4">BASIC PLAN</h3>
                            </div>
                            <div className="p-3">
                                <ul className="list-plains list-unstyled">
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2" >For A Month</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">Free consultaion to coaches</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">Access to The Community</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-3">
                                <button className="btn-plain w-100 border-0 text-white py-2">Joun Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="box-plain d-flex flex-column justify-content-between bg-white position-relative" style={{minHeight:"500px"}}>
                            <span className="plain-icon position-absolute top-0 start-50 translate-middle d-flex justify-content-center align-items-center rounded-circle"><i className="fa-solid fa-crown"></i></span>
                            <span className="plain-price position-absolute top-0 end-0 d-flex justify-content-center fw-light align-items-center">
                                $250
                            </span>
                            <div className="pt-5">
                                <h3 className="name-plain fw-light px-3 py-4">PREMIUM  PLAN</h3>
                            </div>
                            <div className="p-3">
                                <ul className="list-plains list-unstyled">
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">For 10 Month</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">2 Month Free</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">Consultation of Private Coach</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">Access to The Community</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-3">
                                <button className="btn-plain w-100 border-0 text-white py-2">Joun Now</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <div className="box-plain bg-white d-flex flex-column justify-content-between position-relative">
                            <span className="plain-icon position-absolute top-0 start-50 translate-middle d-flex justify-content-center align-items-center rounded-circle"><i className="fa-solid fa-dumbbell"></i></span>
                            <span className="plain-price position-absolute top-0 end-0 d-flex justify-content-center fw-light align-items-center">
                                $120
                            </span>
                            <div className="pt-5">
                                <h3 className="name-plain fw-light px-3 py-4">Pro PLAN</h3>
                            </div>
                            <div className="p-3">
                                <ul className="list-plains list-unstyled">
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">For 5 Months</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">1 Month Free</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">Free consultaion to coaches</span>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <span className="subicon-plain fs-4" ><i className="fa-solid fa-check"></i></span>
                                        <span className="lead ps-2">Access to The Community</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-3">
                                <button className="btn-plain w-100 border-0 text-white py-2">Joun Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Plain;
