import React from "react";
import "../src/App.css";
import { Routes , Route , useLocation } from "react-router-dom";
import Plain from "./components/plain/plain";
import Life from "./components/life/life";
import Main from "./components/main/main";


const App = () => {
  const location = useLocation();
    return (
      <div className="main-class">
         <Routes>
          <Route path="/" element={<Main />} />
          <Route path="plain" element={<Plain/>} />
          <Route path="life" element={<Life/>} />
        </Routes>
      </div>
    );
}

export default App;
