import {motion} from "framer-motion";
import React from 'react';

const InfoCoache = (props) => {

    const {info} = props;
    const ert = info.map((item)=>{
        return(
            <motion.li key={item.name} 
            whileHover={{x:10}} 
            className="list-group-item">
                <label className="fw-bolder">{item.name}:</label>
                <span className="opacity-75 ps-1">{item.valu}</span>
            </motion.li>
        )
    })
    return (
        <div>
            {ert} 
        </div>
    );
}

export default InfoCoache;
