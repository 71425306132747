import React from 'react';
import "./program.css";
import programImg from "../../assests/endorphins-during-jogging-with-girlfriend.jpg";
import InfoProgram from '../parts/infoProgram';
import {motion} from "framer-motion";

const Program = () => {
    const infoProgram = [
        {
            title:"Cards Programs",
            desc:"In this program, you are trained to improve your strength through many exercises.",
            icon:"fa-solid fa-dumbbell"
        },
        {
            title:"Cards Programs",
            desc:"In this program, you are trained to improve your strength through many exercises.",
            icon:"fa-solid fa-dumbbell"
        },
        {
            title:"Cards Programs",
            desc:"In this program, you are trained to improve your strength through many exercises.",
            icon:"fa-solid fa-dumbbell"
        },
        {
            title:"Cards Programs",
            desc:"In this program, you are trained to improve your strength through many exercises.",
            icon:"fa-solid fa-dumbbell"
        },
    ]
    return (
        <div>
            <div id='program' className="our-programs  position-relative overflow-hidden  py-5" >
                <div className="container">
                    <div className="head-program">
                        <h1 className="fs-3 text-white">Some <span className="pop-head-programe p-1 d-inline-block position-relative">Programs</span> </h1>
                    </div>
                    <div className="row my-5">
                        <div className="col-12 col-md-5">
                            <div className="cards-programs">
                                <InfoProgram info={infoProgram} />
                            </div>
                        </div>
                        <div className="col-12 col-md-7 ps-md-5">
                            <div className="w-100">
                                <img className="w-100 img-fluid img-thumbnail" src={programImg} alt="" />
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="mt-5">
                                        <motion.span 
                                        animate={{y:[0,-20,0]}}
                                        transition={{ repeat:Infinity , ease:'easeOut' , duration:0.5}}
                                        className="circle-programe d-block rounded-circle" ></motion.span>
                                        <h5 className="head-subprograme text-white py-4" >Personal Training</h5>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mt-5">
                                        <motion.span
                                        animate={{y:[0,-20,0]}}
                                        transition={{ repeat:Infinity , ease:'easeOut' , duration:0.6}} 
                                        className="circle-programe d-block rounded-circle" ></motion.span>
                                        <h5 className="head-subprograme text-white py-4" >Group Training</h5>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mt-5">
                                        <motion.span
                                        animate={{y:[0,-20,0]}}
                                        transition={{ repeat:Infinity , ease:'easeOut' , duration:0.5}} 
                                        className="circle-programe d-block rounded-circle" ></motion.span>
                                        <h5 className="head-subprograme text-white py-4" >Private Training</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Program;
