import React from 'react';

const InfoProgram = (props) => {
    const {info} = props;
    const ert = info.map((item)=>{
        return(
            <div key={item.title} className="position-relative bg-white w-100 p-3  mb-4" style={{height:"120px"}}>
                <span className="rounded-circle fs-3 d-flex justify-content-center align-items-center text-white"><i className={item.icon}></i></span>
                <h5>{item.title}</h5>
                <p className="opacity-75" >{item.desc}</p>
            </div>
        )
    })
    return (
        <div>
            {ert} 
        </div>
    );
}

export default InfoProgram;
