import { motion } from 'framer-motion';
import React from 'react';

const ContactFild = (props) => {
    const {info} = props;
    const ert = info.map((item)=>{
        return(
            <motion.li key={item.name}
            whileHover={{x:10}} 
            className="d-flex gap-2 py-3 pr-3 text-white align-items-center  border-1 border-white border-bottom">
                <span className="icon-contact fs-4"><i className={item.icon}></i></span>
                <label>{item.name}</label>
                <span>{item.valu}</span>
            </motion.li> 
        )
    })
    return (
        <div>
            {ert} 
        </div>
    );
}

export default ContactFild;
