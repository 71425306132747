import React from 'react';
import {motion} from "framer-motion";
import "./coache.css";
import coacheImg from "../../assests/young-sports-people-training-morning-gym.jpg";
import InfoCoache from '../parts/infoCoache';
const Coache = () => {

    // const table1ref = useRef(null);
    // const [table1Size, table1SizeSet] = useState({
    // width: undefined,
    // height: undefined,
    // });

    // useEffect(() => {
    //     function handleResize() {
    //     table1SizeSet({
    //         width: table1ref.current.offsetWidth,
    //         height: table1ref.current.offsetHeight,
    //     });
    //     }
    //     window.addEventListener("resize", handleResize);
    //     handleResize();
    //     return () => window.removeEventListener("resize", handleResize);        
    // }, [ ]);
    // var t = 0;
    // var tt = (parseInt(table1Size.width) - 150)
    // var ttt = (parseInt(table1Size.height) - 150)

    const infoCoache = [
        {
            name: "Coache" ,
            valu: "Alex D Marshil"
        },
        {
            name: "Expertise" ,
            valu: "3 Years Expertise"
        },
        {
            name: "Age" ,
            valu: "27 years"
        },
        {
            name: "Availability Times" ,
            valu: "Everyday from 10:00 PM to 12:00 AM"
        },
        {
            name: "Long" ,
            valu: "175 cm"
        }
        ,
        {
            name: "Email" ,
            valu: "alex@gmail.com"
        }
    ]
 
    return (
        <div>
            <div id='coache' className="our-Coache position-relative overflow-hidden  py-5" >
                <div className="container bg-white">
                    <div className="pt-4">
                        <h1 className="fs-2 text-dark fw-light">Get To Know <span className="head-coache-mian text-white px-2 py-4">The Coaches</span>  </h1>
                    </div>
                    <div className="row my-5 pb-5">
                        <div className="col-12 col-md-6">
                            <ul className="list-group  list-group-flush">
                                <InfoCoache info={infoCoache} />   
                            </ul>

                            <ul className="list-coche-social list-unstyled list-inline text-dark mt-5 float-end">
                                <li className="list-inline-item">
                                    <motion.span whileHover={{scale:1.3 , color:"#F66B0E"}} className="fs-3 p-3 d-inline-block"><i className="fa-brands fa-instagram"></i></motion.span>
                                </li>
                                <li className="list-inline-item">
                                    <motion.span whileHover={{scale:1.3 , color:"#F66B0E"}} className="fs-3 p-3 d-inline-block"><i className="fa-brands fa-facebook-messenger"></i></motion.span>
                                </li>
                                <li className="list-inline-item">
                                    <motion.span whileHover={{scale:1.3 , color:"#F66B0E"}} className="fs-3 p-3 d-inline-block"><i className="fa-brands fa-snapchat"></i></motion.span>
                                </li>
                                <li className="list-inline-item">
                                    <motion.span whileHover={{scale:1.3 , color:"#F66B0E"}} className="fs-3 p-3 d-inline-block"><i className="fa-brands fa-whatsapp"></i></motion.span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 ps-5">
                            <div className="row">
                                <div className="col-1">
                                    <div className="arrow-coache d-flex flex-column h-100 justify-content-between">
                                        <span className=" fs-3"><i className="fa-solid fa-arrow-up"></i></span>
                                        <span className=" fs-3"><i className="fa-solid fa-arrow-down"></i></span>
                                    </div>
                                </div>
                                <div className="col-11">
                                    <div className="w-100 position-relative" style={{height:"350px"}} >
                                        <span
                                        className="box-img-one position-absolute"></span>
                                        <span 
                                        className="box-img-tow position-absolute"></span>
                                        <img className="w-100 h-100 p-2 position-absolute" style={{objectFit: "cover",zIndex: "9"}} src={coacheImg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Coache;
